<template>
  <div>
    <section class="section is-main-section">
      <h1 class="title">
        {{ title }}
      </h1>
      <div class="columns">
        <div class="column is-2">
          <b-datepicker
              v-model="fromdate"
              placeholder="ຈາກວັນທີ"
              :locale="this.getDateLocale"
              icon="calendar-today"
              @input="selectFromDate"
          >
          </b-datepicker>
        </div>
        <div class="column is-2">
          <b-datepicker
              v-model="todate"
              placeholder="ຫາວັນທີ"
              :locale="this.getDateLocale"
              icon="calendar-today"
              @input="selectToDate"
          >
          </b-datepicker>
        </div>
        <div class="column is-3"  v-if="user.role==='ADMIN'">
          <b-select placeholder="ສາຂາ" expanded v-model="branchId">
            <option v-for="option in branchs"
                    :value="option.id"
                    :key="option.id">
              {{ option.name }}
            </option>
          </b-select>
        </div>
        <div class="column is-3">
          <b-select placeholder="ສະຖານະ" expanded v-model="deliveryMethod">
            <option v-for="option in deliveryOption"
                    :value="option.id"
                    :key="option.id">
              {{ option.text }}
            </option>
          </b-select>
        </div>
        <div class="column is-2">
          <button class="button" @click="get">
            <b-icon icon="magnify" />
          </button>
        </div>
      </div>
      <card-component class="has-table has-mobile-sort-spaced">
        <div class="columns" style="padding: 1em">
          <div class="column title has-text-centered">
            {{ models.length }} ລາຍການ
          </div>
          <div class="column title has-text-centered">
            {{ _.sumBy(models, "amount") | formatnumber }} ກີບ
          </div>
          <div class="column title has-text-centered">
            {{ _.sumBy(models, "amount_thb") | formatnumber }} ບາດ
          </div>
        </div>
        <hr />
        <b-table
            :loading="isLoading"
            :paginated="perPage < models.length"
            :per-page="perPage"
            :striped="true"
            :hoverable="true"
            default-sort="created_date"
            :data="models"
            :current-page.sync="currentPage"
            :pagination-rounded="true"
            :narrowed="true" class="is-size-7"
        >
          <b-table-column label="#" v-slot="props">
            {{ props.index+(currentPage>1?(perPage*currentPage-perPage):0)+1 }}
          </b-table-column>
          <b-table-column
              label="ວັນທີ"
              field="created_date"
              sortable
              v-slot="props"
          >
            {{ props.row.created_date | datetime }}
          </b-table-column>
          <b-table-column label="ລະຫັດ" field="code" sortable v-slot="props">
            {{ props.row.code }}
          </b-table-column>
          <b-table-column
              label="ຜູ້ສົ່ງ"
              field="sendername"
              sortable
              v-slot="props"
          >
            {{ props.row.sendername }}
          </b-table-column>
          <b-table-column
              label="ຜູ້ຮັບ"
              field="receivername"
              sortable
              v-slot="props"
          >
            {{ props.row.receivername }}
          </b-table-column>
          <b-table-column
              label="ສາຂາຕົ້ນທາງ"
              field="branch_branchToshipment_from_branch_id.name"
              sortable
              v-slot="props"
          >
            {{ props.row.branch_branchToshipment_from_branch_id.name }}
          </b-table-column>
          <b-table-column
              label="ສາຂາປາຍທາງ"
              field="branch_branchToshipment_to_branch_id.name"
              sortable
              v-slot="props"
          >
            {{ props.row.branch_branchToshipment_to_branch_id.name }}
          </b-table-column>
          <b-table-column
              label="ຈຳນວນເງິນກີບ"
              field="amount"
              sortable
              v-slot="props"
              cell-class="has-text-right"
          >
            {{ props.row.amount | formatnumber }}
          </b-table-column>
           <b-table-column
            label="ຈຳນວນເງິນບາດ"
            field="amount_thb"
            sortable
            v-slot="props"
            cell-class="has-text-right"
          >
            {{ props.row.amount_thb | formatnumber }}
          </b-table-column>
          <b-table-column
              label="ສະຖານະ"
              sortable
              field="status"
              v-slot="props"
              cell-class="has-text-centered"
          >
            <shipment-status :status="props.row.status" />
          </b-table-column>

          <empty-table v-if="models.length == 0" :isLoading="isLoading" />
        </b-table>
      </card-component>
    </section>
  </div>
</template>

<script>
import axios from "axios";
import { get } from "vuex-pathify";
import CardComponent from "@/components/CardComponent";
import EmptyTable from "../components/EmptyTable.vue";
import ShipmentStatus from "../components/ShipmentStatus.vue";
import moment from "moment";
import store from "@/store";
import helper from "@/helper";
export default {
  name: "ReportShipmentReject",
  components: { CardComponent, EmptyTable, ShipmentStatus },
  computed: {
    user: get("user"),
  },
  data() {
    return {
      currentPage: 1,
      perPage: 20,
      title: "ລາຍງານເຄື່ອງຖືກຕີກັບ",
      models: [],
      isShowModal: false,
      isLoading: false,
      fromdate: new Date(),
      todate: new Date(),
      branchId: 0,
      branchs: [],
      deliveryMethod: `${helper.SHIPMENT_STATUS.DELIVERED},${helper.SHIPMENT_STATUS.ARRRIVED_DESTINATION_BRANCH}`,
      deliveryOption: [
        {
          text: "ທັງໝົດ",
          id: `${helper.SHIPMENT_STATUS.DELIVERED},${helper.SHIPMENT_STATUS.ARRRIVED_DESTINATION_BRANCH}`,
        },{
          text: "ລໍຖ້າຈັດສົ່ງ",
          id: helper.SHIPMENT_STATUS.ARRRIVED_DESTINATION_BRANCH,
        },
        {
          text: "ຈັດສົ່ງແລ້ວ",
          id: helper.SHIPMENT_STATUS.DELIVERED,
        },
      ],
    };
  },
  mounted() {
    this.getBranch();
    this.getUserBranchId();
    this.get();
  },
  watch:{
    models:function() {
      this.isLoading = false;
    },
  },
  methods: {
    get() {
      this.isLoading=true;
      const fdate = moment(this.fromdate).format("YYYY-MM-DD");
      const tdate = moment(this.todate).format("YYYY-MM-DD");
      axios.get(`report/shipmentsrejecttobranch/${fdate}/${tdate}/${this.branchId}/${this.deliveryMethod}`).then((r) => {
        this.models = r;
      });
    },
    getUserBranchId(){
      this.branchId=store.getters.getUserBranchId;
    },
    getBranch(){
      this.branchs=store.getters.getBranch;
    },
    view(item) {
      console.log(item);
      this.$router.push({ path: "/report/shipment/" + item.id });
    },
    selectFromDate(e) {
      console.log(e);
    },
    selectToDate(e) {
      console.log(e);
    },
  },
};
</script>