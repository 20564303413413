<template>
  <section slot="empty" class="section">
    <div class="content has-text-grey has-text-centered">
      <template v-if="isLoading">
        <p>
          <b-icon icon="dots-horizontal" size="is-large" />
        </p>
        <p>ກຳລັງໂຫຼດ...</p>
      </template>
      <template v-else>
        <p>
          <b-icon icon="emoticon-sad" size="is-large" />
        </p>
        <p>ບໍ່ພົບຂໍ້ມູນ &hellip;</p>
      </template>
    </div>
  </section>
</template>

<script>
export default {
  name: "EmptyTable",
  props: {
    isLoading: {
      type: Boolean,
      default: null,
    },
  },
};
</script>